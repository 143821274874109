<template>
    <div class="option_terminal">
        <vs-row class="pb-5">
            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                <h3>Délais de réservation</h3>
                <vs-button color="success" type="filled" v-on:click="update" >Enregistrer</vs-button>
            </vs-col>
            <vs-col vs-xs="12" vs-lg="6" class="p-3">
                <vx-card>
                    <h3>Délai minimal de réservation</h3>
                    <hr/>
                    <br/>
                    <vs-row vs-type="flex">
                        <vs-col vs-xs="6" vs-lg="6" class="pr-3" vs-justify="left" vs-align="left">
                            <vs-input class="w-full" label="Délai" v-model="addTime" />
                        </vs-col>
                        <vs-col vs-xs="6" vs-lg="6" class="pr-3" vs-justify="left" vs-align="left">
                            <vs-select label="Unité" class="w-full" v-model="time_booking_add_unit" >
                                <vs-select-item value="m" text="Minutes" />
                                <vs-select-item value="h" text="Heures" />
                            </vs-select>
                        </vs-col>
                        <vs-col vs-xs="12" vs-justify="left" class="p-3" vs-align="left">
                            <p>Le client pourra réserver jusqu'à <b>{{ addText }}</b> avant la séance.</p>
                        </vs-col>
                    </vs-row>
                </vx-card>
            </vs-col>

            <vs-col vs-xs="12" vs-lg="6" class="p-3">
                <vx-card>
                    <h3>Délai minimal d'annulation</h3>
                    <hr/>
                    <br/>
                    <vs-row>
                        <vs-col vs-xs="6" vs-lg="6" class="pr-3" vs-justify="left" vs-align="left">
                            <vs-input class="w-full" label="Délai" v-model="delTime" />
                        </vs-col>
                        <vs-col vs-xs="6" vs-lg="6" class="pr-3" vs-justify="left" vs-align="left">
                            <vs-select label="Unité" class="w-full" v-model="time_booking_del_unit" >
                                <vs-select-item value="m" text="Minutes" />
                                <vs-select-item value="h" text="Heures" />
                            </vs-select>
                        </vs-col>
                        <vs-col vs-xs="12" vs-justify="left" class="p-3" vs-align="left">
                            <p>Le client pourra annuler sa séance jusqu'à <b>{{ delText }}</b> avant l'heure de début.</p>
                        </vs-col>
                    </vs-row>
                </vx-card>

            </vs-col>
        </vs-row>
        <vs-divider/>
        <vs-row class="pt-5 pb-3">
            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                <h3>Liste des machines</h3>
                <vs-button color="success" type="filled" v-on:click="openAddMachine" icon-pack="feather" icon="icon-plus" >Ajouter</vs-button>
            </vs-col>
        </vs-row>
        <div>
            <!-- <div v-for="machine in list_machine" :key="machine.uid"> -->   
            <vs-row>
                <vs-col v-for="machine in list_machine" :key="machine.uid" vs-type="flex" vs-justify="left" vs-align="left" vs-xs="12" vs-md="6" vs-lg="4" class="p-3">
                    <vx-card>
                        <vs-row>
                            <vs-col vs-type="flex">
                                <div class="con-s">
                                    <vs-switch color="success" v-model="machine.active" class="mr-4" v-on:change="changeActif( machine.uid, machine.active )">
                                        <span slot="on">Actif</span>
                                        <span slot="off">Inactif</span>
                                    </vs-switch>
                                </div>

                                <b class="cursor" @click="toPage('/admin/option/machine/'+machine.uid)" >
                                {{ machine.name }} 
                                <template v-if="machine.groupe.length>0">
                                    / {{ machine.groupe }}
                                </template>
                                </b>
                            </vs-col>
                        </vs-row>
                    </vx-card>
                </vs-col>
            </vs-row>

            <p v-if="list_machine.length==0" class="center">Aucune machine</p>
        </div>
        <AddMachine ref="addMachine" />
    </div>
</template>



<style lang="scss">
</style>

<script>
import autoRefresh from '@/assets/utils/autoRefresh'
import Machine from '@/database/models/machine'
import AddMachine from '@/components/machine/add.vue'
import Studio from '@/database/models/studio'

export default {
    components:{
        AddMachine,
	},
    data(){
        return{
            list_machine:[],

            studio: {
                time_booking_add : 0,
                time_booking_del : 0,
            },

            time_booking_add_unit: 'm',
            addTime: 0,
            time_booking_del_unit: 'm',
            delTime: 0,
        }
	},
	mounted:function()
	{
        //autoRefresh
		autoRefresh.SetCallback(()=>{
            //refresh
            Machine.getTabAllMemoryClone((list_machine)=>
            {
                //triage par groupe
                list_machine.sort(function(a, b)//trier valeur ( ATTENTION CREE DES DOUBLONS )
                {
                    var ca  = a.groupe.toUpperCase();
                    var cb  = b.groupe.toUpperCase();
                    return ('' + ca).localeCompare(cb);
                });

                this.list_machine = list_machine
            })

            Studio.getAllMemory((list_studio)=>
            {
                //find actual studio
                const studio = JSON.parse(JSON.stringify( list_studio[ this.$srvApi.getStudio() ] ));

                this.studio.time_booking_add = studio.time_booking_add
                this.studio.time_booking_del = studio.time_booking_del
                this.setTimes()
            })

        })
        autoRefresh.refresh()
    },
    watch:{
        $route (){
            autoRefresh.refresh()
        }
    },
    computed: {
        addText() {
            return this.getTimeText(this.time_booking_add_unit, this.addTime)
        },
        delText() {
            return this.getTimeText(this.time_booking_del_unit, this.delTime)
        },
        addInMinutes() {
            return this.calcInMinutes(this.time_booking_add_unit, this.addTime)
        },
        delInMinutes() {
            return this.calcInMinutes(this.time_booking_del_unit, this.delTime)
        }
    },
    methods: {
        setTimes() {
            if(this.studio.time_booking_add / 60 > 1) {
                this.time_booking_add_unit = 'h'
                this.addTime = this.studio.time_booking_add / 60
            } else {
                this.studio.time_booking_add_unit = 'm'
                this.addTime = this.studio.time_booking_add
            }

            if(this.studio.time_booking_del / 60 > 1) {
                this.time_booking_del_unit = 'h'
                this.delTime = this.studio.time_booking_del / 60
            } else {
                this.studio.time_booking_del_unit = 'm'
                this.delTime = this.studio.time_booking_del
            }
        },
        getTimeText(unit, time) {
            let status = `${time} `
            switch(unit) {
                case 'm':
                    status += 'minute'
                    break
                case 'h':
                    status += 'heure'
                    break
            }
            if (time > 1) status += 's'
            return status
        },
        calcInMinutes(unit, time) {
            let timeBuffer = time
            if (unit === 'h') timeBuffer *= 60
            else timeBuffer *= 1
            return timeBuffer
        },
		update(){
            //nettoyage vide
            this.data = {
                time_booking_add : this.addInMinutes,
                time_booking_del : this.delInMinutes,
            }

            //envoi mise a jour
            Studio.update( this.$srvApi.getStudio(), this.data )
            .then(()=>{
                alert('Modification enregistrée')
            })
            .catch(()=>{
                alert('Une erreur est survenue !')
            })
        },
		openAddMachine(){
            this.$refs.addMachine.openPopup(()=>{
                autoRefresh.refresh();
            })
        },
        toPage( url )
		{
			this.$router.push(url).catch(() => {});
		},
        changeActif( uid, value )
        {
            // console.log(uid)
            Machine.update(uid, {
                'active':value
            })
            .then(()=>{
                //alert("Modification enregistrée");
            })
        }
    }
};

</script>